.particle-system-container {
	width: 100vw;
    height: 100vw;
    max-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;

	.tsparticles {
		position: absolute;
		top: 0%;
		right: 0%;
		height: 100%;
		width: 100%;
		max-height: 100%;
		min-height: 50%;
		pointer-events: none;
	}
}
