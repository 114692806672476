.contact-parent-container {
	position: absolute;
	width: 100%;
	min-height: 50vh;
	background-color: black;
	display: flex;
	align-items: center;
	flex-direction: column;
	color: white;
	box-sizing: border-box;

	.contact-header {
		width: 100%;
		text-align: center;
		font-size: 4.5vmin;
		font-family: "Montserrat Bold", sans-serif;
		margin: 20px 0 40px 0;
	}

	.contact-content-container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: space-evenly;

		@media (max-width: 768px) {
			flex-direction: column; /* Change flex direction on small screens */
			align-items: center; /* Center items horizontally */
		}

		.contact-info-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 40px;

			.contact-info-row {
				width: 100%;
				display: flex;
				align-items: center;

				.contact-info-icon-container {
					margin: 8px;
					width: 60%;

					@media (max-width: 768px) {
						width: auto;
						margin-left: 10%;
					}

					display: flex;
					justify-content: flex-end;
					.contact-info-icon {
						height: 40px;
						width: 40px;
					}
				}

				.contact-info {
					width: 100%;
					font-family: "Montserrat Regular", sans-serif;
					text-transform: none;
					user-select: all;
				}
			}
		}

		.contact-form-container {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;

			.contact-form {
				height: 100%;
				width: 80%;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;
				font-family: "Montserrat Bold", sans-serif;
				text-transform: uppercase;

				.required {
					color: #999999;
				}

				.form-name-group {
					display: flex;
					width: 100%;

					.first-name {
						padding-right: 8px;
					}
				}

				.form-group {
					flex: 1;
					display: flex;
					flex-direction: column;
					width: 100%;
					margin: 4px 0;
					border: none;
					border-radius: 0;

					input,
					textarea {
						padding: 4px;
						font-family: "Montserrat Bold", sans-serif;
						width: 100%;
					}

					textarea {
						resize: vertical;
						min-height: 5vh;
						@media (max-width: 768px) {
							min-height: 5vh; // Make the textarea bigger by default on mobile devices
						}
						max-height: 25vh;
					}
				}

				.submit-form-button {
					width: 100%;
					margin: 16px 0 40px 0;
					padding: 8px;
					font-size: large;
					font-family: "Montserrat Bold", sans-serif;
					text-transform: uppercase;
					background-color: black;
					border: 2px solid;
					transition: all 0.35s;
				}

				.submitted {
					color: #999999 !important;
					border-color: #999999 !important;
				}
			}
		}
	}
}

.full-screen {
	min-height: calc(100% - 90px);

	.contact-content-container {
		height: auto;
	}
}

.contact-parent-container.invert-colors {
	background-color: white;
	color: black;
	.contact-content-container {
		.contact-form-container {
			.contact-form {
				.submit-form-button {
					background-color: white;
				}
			}
		}
	}
}

.contact-parent-container.remove-header {
	.contact-header {
		opacity: 0;
		@media (max-width: 768px) {
			display: none;
		}
	}
}
