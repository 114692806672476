.swiper-container {
	width: 100%;
	text-align: center;
	position: absolute;
	pointer-events: all;
}

.swiper-wrapper {
	display: flex;
	align-items: center;
	overflow-x: visible;
	transition-timing-function: inherit !important;
}

.swiper-slide {
	height: auto;
}

.swiper-content {
	width: 90%;
	object-fit: contain;
}

/* Swiper Navigation Buttons */
.swiper-button-next,
.swiper-button-prev {
	pointer-events: all;
	color: black !important;
}

/* Swiper Pagination Bullets */
.swiper-pagination-bullet {
	pointer-events: all;
	background-color: black !important;
}

/* Optional: To change the color of the active pagination bullet */
.swiper-pagination-bullet-active {
	pointer-events: all;
	background-color: black !important;
}
