.parallax-container {
	position: relative;
	pointer-events: none;
	width: 100%;

	.parallax-mask {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;

		.parallax-banner {
			top: 0;
			height: 150%;
			pointer-events: none;

			div {
				display: flex;
				align-items: center;
				background-color: black;
				* {
					height: auto !important;
					aspect-ratio: 16/9;
					inset: 0 !important;
				}
			}
		}
	}
}
