.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border: 1px solid #ccc;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	z-index: 1000;
    min-width: 300px;
	max-width: 90%;
	text-align: center;
	border-radius: 5px;

	&-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 999;
	}

	&-close {
		position: absolute;
		top: 10px;
		right: 10px;
		background: none;
		border: none;
		font-size: 20px;
		cursor: pointer;
        color: black;
	}

	.popup-header {
		font-family: "Montserrat Bold", sans-serif;
		font-size: 8vmin;
        margin: 20px;
	}

	.popup-message {
		font-family: "Montserrat Regular", sans-serif;
		font-size: 5vmin;
        margin-bottom: 20px;
        color: #c3113c;
	}
}
