.case-study-container {
	width: calc(100% - ((var(--swiper-navigation-size) / 44* 27) * 2) - 100px);
	max-height: 80%;
	overflow: hidden;
	@media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
    }

	.case-study-title-container {
        pointer-events: all;
        z-index: 1;
		position: relative;
		display: flex;
		width: 100%;
		overflow: visible;

		.case-study-title {
			width: 100%;
			white-space: nowrap;
			@media (max-width: 768px) {
				white-space: normal; /* Change flex direction on small screens */
			}
			display: flex;
			justify-content: center;
			align-items: center;
			text-transform: uppercase;
			font-size: 9vmin;
			font-family: "Montserrat Extra Bold", sans-serif;
			--s: 20px; /* the size on the corner */
			--t: 2px; /* the thickness of the border */
			--gt: 12px; /* the gap between the top border and image */
			--gs: 24px; /* the gap between the side border and image */

			padding: calc(var(--gt) + var(--t)) calc(var(--gs) + var(--t) - 8px);
			outline: var(--t) solid #c3113c;
			outline-offset: calc(-1 * var(--t));
			mask: conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 / calc(100% - var(--s)) calc(100% - var(--s)), linear-gradient(#000 0 0) content-box;

			.case-study-subtitle {
				width: 100%;
				margin-left: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;
				font-size: 2.75vmin;
				font-family: "Montserrat Bold", sans-serif;
			}
		}

		.case-study-title-spacer {
			width: 200%;

			@media (max-width: 768px) {
				width: 0; /* Change the spacer width on small screens */
			}
		}
	}

	.case-study-info {
        pointer-events: all;
        position: relative;
        z-index: 1;
		width: 50%;
        height: 100%;
        @media (max-width: 768px) {
            width: 100%; /* Change the width on small screens */
            height: 50%;
        }
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		.case-study-text {
            @media (min-width: 768px) {
                margin-top: 24px; /* Add margin for bigger screen sizes */
            }
			text-transform: none;
			display: flex;
			flex-direction: column;
			align-items: center;

			.case-study-header {
				text-align: left;
				width: 90%;
				font-size: 2.5vmin;
				@media (max-width: 768px) {
					font-size: 3vmin;
				}
				font-family: "Montserrat Bold", sans-serif;
				color: #c3113c;
			}

			.case-study-body {
				width: 90%;
				font-size: 2vmin;
				font-family: "Montserrat Medium", sans-serif;
				text-align: left;
				@media (max-width: 768px) {
					font-size: 2.5vmin;
					text-align: justify;
				}
			}
		}

		.case-study-video {
			@media (min-width: 768px) {
                margin-top: 24px; /* Add margin for bigger screen sizes */
            }
			width: 100%;
			max-height: 25%;
			aspect-ratio: 4 / 1;
			display: flex;
			justify-content: center;
			align-items: center;

			div {
				flex-direction: row !important;
			}
		}
	}        
        .case-study-image {
            position: absolute;
            width: 65%;
            top: 0;
            bottom: 0;
            right: -2%;
            margin: auto;
            display: flex;
            align-items: flex-start;
			object-fit: contain;
            @media (max-width: 768px) {
                position: relative;
                width: 100%;
                margin: unset;
            }
		}
}
