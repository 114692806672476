.path-container {
	width: 100vw;
	display: flex;
	justify-content: space-between;
	position: absolute;
}

.path {
	width: 100%;
	height: min-content;
	stroke: #000000;
	transition: stroke 0.35s;

	&.scrolling {
		stroke: #c3113c;
	}
}
