.fade-in {
	opacity: 0;
	animation: fadeIn 3s forwards;
}

@keyframes fadeIn {
	to {
		opacity: 1;
	}
}

.fade-in-15 {
	opacity: 0;
	animation: fadeIn15 3s forwards;
}

@keyframes fadeIn15 {
	to {
		opacity: 0.15;
	}
}
