.case-studies-page {
	height: calc(100% - 90px);
	width: 100%;
	position: absolute;
	left: 0;
	text-transform: uppercase;
	transition: 0.35s;
	display: flex;

	.carousel-container {
		width: 100%;
		height: 100%;
		transition: 0.35s;
		display: flex;

		div {
			.swiper-wrapper {
				.swiper-slide {
					height: 100%;
					width: 100% !important;
					display: flex;

					> div {
						max-height: 100%;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						box-sizing: border-box;
					}
				}
			}
		}
	}
}
