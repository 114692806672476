.carousel-parent {
	position: absolute;
	width: 100vw;
	z-index: 20;

	.carousel-section-container {
		position: relative;

		.carousel-div-container {
			position: sticky;
			overflow: hidden;
			display: flex;
			flex-direction: column;
            justify-content: center;

			.carousel-slides-container {
				display: flex;

				.carousel-motion-container {
					display: flex;

					.carousel-element {
						height: auto;
						aspect-ratio: 9 / 16;
						position: relative;
						height: 100%;
						overflow: hidden;
						display: flex;

						.carousel-content-container {
							height: auto;
							aspect-ratio: 9 / 16;
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 0;
							display: flex;
							align-items: center;
							justify-content: center;

							overflow: visible;
							// transition: transform 035s;
							.carousel-content {
								width: 90%;
								height: auto;
								aspect-ratio: 9 / 16;
								object-fit: cover;
								border-radius: 12px;
								overflow: hidden;

								&:hover {
									transform: scale(1.1);
								}
							}
						}
					}
				}
			}
		}
	}
}
