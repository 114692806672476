.home-page {
	text-transform: uppercase;
	transition: 0.35s;
	display: flex;

	.lets-go-container {
		position: absolute;
		width: 100%;
		height: auto;
		aspect-ratio: 16 / 4.82;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url("../../../assets/images/icons/clawed-icon.svg");
		background-repeat: no-repeat;
		background-position: center;
	}
}
