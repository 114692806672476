// <----- MONSTSERRAT -----> \\

@font-face {
	font-family: "Montserrat Black Italic";
	src: url("../../assets/fonts/montserrat/montserrat-black-italic.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat Black";
	src: url("../../assets/fonts/montserrat/montserrat-black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat Bold Italic";
	src: url("../../assets/fonts/montserrat/montserrat-bold-italic.ttf") format("truetype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat Bold";
	src: url("../../assets/fonts/montserrat/montserrat-bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat Extra Bold Italic";
	src: url("../../assets/fonts/montserrat/montserrat-extra-bold-italic.ttf") format("truetype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat Extra Bold";
	src: url("../../assets/fonts/montserrat/montserrat-extra-bold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat Extra Light Italic";
	src: url("../../assets/fonts/montserrat/montserrat-extra-light-italic.ttf") format("truetype");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat Extra Light";
	src: url("../../assets/fonts/montserrat/montserrat-extra-light.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat Italic";
	src: url("../../assets/fonts/montserrat/montserrat-italic.ttf") format("truetype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat Light Italic";
	src: url("../../assets/fonts/montserrat/montserrat-light-italic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat Light";
	src: url("../../assets/fonts/montserrat/montserrat-light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat Medium Italic";
	src: url("../../assets/fonts/montserrat/montserrat-medium-italic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat Medium";
	src: url("../../assets/fonts/montserrat/montserrat-medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat Regular";
	src: url("../../assets/fonts/montserrat/montserrat-regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat Semi Bold Italic";
	src: url("../../assets/fonts/montserrat/montserrat-semibold-italic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat Semi Bold";
	src: url("../../assets/fonts/montserrat/montserrat-semibold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat Thin Italic";
	src: url("../../assets/fonts/montserrat/montserrat-thin-italic.ttf") format("truetype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat Thin";
	src: url("../../assets/fonts/montserrat/montserrat-thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
}
