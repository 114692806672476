.interactive-path-poi {
	font-family: "Montserrat Bold", sans-serif;
	font-size: xxx-large;

	.interactive-path-icon-background {
		opacity: 0;
		transition: opacity 0.35s;
		overflow: visible;
        stroke: none;
	}

	.interactive-path-icon {
		opacity: 0;
		transition: opacity 0.35s;
		overflow: visible;
	}

	.interactive-path-circle {
		opacity: 1;
		transition: opacity 0.35s;
	}

	.interactive-path-poi-header {
		stroke: #000000;
	}

	.interactive-path-poi-body {
		opacity: 0;
		fill: #c3113c;
		stroke: #c3113c;
		text-transform: none;
		font-family: "Montserrat Regular", sans-serif;
		word-wrap: break-word;
		overflow: hidden;
		max-width: 200px;
		max-height: 500px;
		font-size: large;
		transition: opacity 0.35s;
	}

	&:hover .interactive-path-icon-background, &:hover .interactive-path-icon {
		@media (min-width: 768px) {
			opacity: 1;
		}
	}

	&:hover .interactive-path-circle {
		@media (min-width: 768px) {
			opacity: 0;
		}
	}

	&:hover .interactive-path-poi-body {
		@media (min-width: 768px) {
			opacity: 1;
		}
	}

}
