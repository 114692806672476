.who-we-are-page {
	width: 100%;
	height: calc(100% - 90px);
	position: absolute;
	background-color: black;
	text-transform: uppercase;
	transition: all 0.35s;
	display: flex;
	flex-direction: column;
	color: white;
	text-align: center;

	.background-image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all 0.35s;
		filter: grayscale(100%) blur(1px) contrast(75%); /* Apply multiple filters */
		opacity: 0.15 ;
	}

	.heading {
		z-index: 1;
		margin-top: 100px;
		padding: 20px 5%;
		width: 100%;
		font-size: 7vmin;
		font-family: "Montserrat Extra Bold", sans-serif;
		transition: all 0.35s;
	}
	
	.subheading {
		text-transform: none;
		z-index: 1;
		padding: 20px 5%;
		font-size: 4.25vmin;
		font-family: "Montserrat", sans-serif;
		transition: all 0.35s;
	}

	span {
		color: #c3113c;
	}
}
