.floating-header {
	position: fixed;
	top: 0;
	left: 0;
	width: calc(100% - 40px);
	margin: 10px 20px 0px 20px;
	height: 70px;
	background-color: #fff; // Set your desired background color
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Add shadow for a floating effect
	border-radius: 4px;
	z-index: 999; // Ensure the header appears above other content
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.blur-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 90px;
	z-index: 998; // Ensure the blur header appears above other content
	/* Create a mask that fades from opaque to transparent to apply more of a blur to content higher on the screen */
	mask: linear-gradient(black, black, black, transparent);
	/* Apply a blur effect */
	backdrop-filter: blur(20px); // Adjust the blur radius as needed
}

.spacer-header {
	height: 90px; /* Adjust the height to match your header height */
}

.logo {
    width: 50%;
    max-width: 250px;
	margin: 20px;
}

/* Navigation button container */
.nav-buttons {
	display: flex;
	align-items: center;
}

/* Basic button styles */
.nav-button {
	display: inline-block;
	padding: 5px;
	color: black;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
	border: 1px solid rgba(0, 0, 0, 0);
	cursor: pointer;
	transition: background-color 0.35s ease;
	font-family: "Montserrat Bold", sans-serif;
}

/* Hover effect */
.nav-button:hover {
	border: 1px solid #c3113c;
	color: #c3113c;
}

/* Space between buttons */
.nav-button + .nav-button {
	margin-left: 40px;
}

/* Hamburger Menu */
.hamburger {
	display: none;
	flex-direction: column;
	cursor: pointer;
	padding: 20px;
}

.hamburger-line {
	width: 30px;
	height: 3px;
	background-color: #999999;
	margin: 4px 0;
	transition: 0.4s;
}

.hamburger-line.open:nth-child(1) {
	transform: rotate(-45deg) translate(-7.5px, 7.5px);
}

.hamburger-line.open:nth-child(2) {
	opacity: 0;
}

.hamburger-line.open:nth-child(3) {
	transform: rotate(45deg) translate(-7.5px, -7.5px);
}

.nav-buttons.open {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: absolute;
	top: 78px;
	right: 0;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);

	.nav-button:hover {
		border: 1px solid rgba(0, 0, 0, 0);
		color: #c3113c;
	}

	.active {
		color: #c3113c;
	}
}


@media (max-width: 768px) {
	.nav-buttons {
		display: none;
	}

	.hamburger {
		display: flex;
	}
}
