.fullscreen-video-player {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  pointer-events: all;

  .play-button {
    pointer-events: all;
    cursor: pointer;
    transition: fill 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .play-title {
      padding: 8px;
      font-size: 3.5vmin;
      font-family: "Montserrat Bold", sans-serif;
    }

    &:hover svg circle {
      stroke: #c3113c;
    }

    &:hover svg polygon {
      fill: #c3113c;
    }

    &:hover {
      color: #c3113c;
    }
  }

  .hidden {
    display: none !important;
  }

  .video-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    iframe {
      width: 90vw; /* Prefer 90% of viewport width */
      height: calc(90vw * 9 / 16); /* Calculate height based on 16:9 aspect ratio */
      max-height: 80vh; /* Ensure the height does not exceed 80% of the viewport height */
      max-width: calc(80vh * 16 / 9); /* Ensure the width does not exceed the calculated aspect ratio limit */
  }  

    .close-button {
      position: absolute;
      bottom: 20px;
      right: 20px;
      background: none;
      color: white;
      border: none;
      cursor: pointer;
      font-size: 1.2rem;

      &:hover {
        color: #c3113c;
      }
    }
  }
}
