.animated-plus {
    width: 12vmin;
    height: 12vmin;
    pointer-events: all;
    z-index: 1;
    stroke: #999999;
  
    animation: scaleUpDown 5s infinite alternate ease-in-out; /* Adjust animation duration and timing as needed */
  
    &:hover {
      stroke: #c3113c; /* Change this to the highlight color */
    }
  
    @keyframes scaleUpDown {
      0% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1.2);
      }
    }
  }
  