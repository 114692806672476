.interactive-box {
	position: absolute;
	display: inline-block; // Ensure the box only takes as much width as needed
	font-size: 7.75vw;
	font-family: "Montserrat Extra Bold", sans-serif;
	margin: 20px;
	cursor: default;
	box-sizing: border-box; // Ensure padding and borders are included in the width

	.bounding-box {
		--s: 20px; /* the size on the corner */
		--t: 2px; /* the thickness of the border */
		--gt: 4px; /* the gap between the top border and image */
		--gs: 16px; /* the gap between the side border and image */

		padding: calc(var(--gt) + var(--t)) calc(var(--gs) + var(--t));
		outline: var(--t) solid #c3113c;
		outline-offset: calc(-1 * var(--t));
		mask: conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 / calc(100% - var(--s)) calc(100% - var(--s)), linear-gradient(#000 0 0) content-box;

		animation: scaleUpAnimation 8s ease-in-out infinite; // Apply the animation to the div
		box-sizing: border-box; // Ensure padding and borders are included in the width

		@keyframes scaleUpAnimation {
			0% {
				transform: scale(1); // Start with normal size
			}
			50% {
				transform: scale(1.05); // Scale up to 1.1x size halfway through the animation
			}
			100% {
				transform: scale(1); // Return to normal size at the end of the animation
			}
		}

		&:hover {
			animation-play-state: paused; // Pause the animation on hover
		}

		.content-box {
			white-space: nowrap;
			animation: scaleDownAnimation 8s ease-in-out infinite; // Apply the animation to the div

			@keyframes scaleDownAnimation {
				0% {
					transform: scale(1); // Start with normal size
				}
				50% {
					transform: scale(0.95); // Scale down to 0.9x size halfway through the animation to counter the parent scaling up
				}
				100% {
					transform: scale(1); // Return to normal size at the end of the animation
				}
			}

			&:hover {
				animation-play-state: paused; // Pause the animation on hover
			}
		}
	}

	.dropdown-content {
		position: absolute;
		color: #c3113c;
		width: 50%;
		height: 0;
		margin-top: 8px;
		opacity: 0; // Initially invisible
		transition: all 0.35s; // Transition for opacity change
		pointer-events: none; // Disable pointer events to prevent interaction when hidden
		padding: 12px;
		background-color: white;

		.bounding-box-header {
			font-size: 3vmin;
			text-transform: none;
			font-family: "Montserrat Bold", sans-serif;
		}

		.bounding-box-body {
			font-size: 2vmin;
			text-transform: none;
			font-family: "Montserrat Medium", sans-serif;
		}
	}

    .center {
        left: 0;
        right: 0;
        margin: auto;
		text-align: center;
    }

    .right {
        right: 10%;
		text-align: right;
    }

	&:hover span {
		color: #c3113c;
		transition: color 0.35s;
	}

	// Reveal dropdown content on hover
	&:hover .dropdown-content {
		height: auto;
		opacity: 1; // Make dropdown content visible
		pointer-events: auto; // Enable pointer events when visible
	}
}
